<template>
    <modal show="show" v-if="isValid('CanAddSubCategory') || isValid('CanEditSubCategory')">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-if="type == 'Edit'">Update Expense SubCategory</h6>
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-else>Add Expense SubCategory</h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    
                    <div class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.subCategory.categoryId.$error }">
                            <label class="text  font-weight-bolder">
                                Expense Category: <span class="text-danger"> *</span>
                            </label>
                            <citydropdown v-model="$v.subCategory.categoryId.$model" v-bind:values="subCategory.categoryId">
                            </citydropdown>

                        </div>
                        <div class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.subCategory.name.$error }">
                            <label class="text  font-weight-bolder">
                                Name: <span class="text-danger"> *</span>
                            </label>
                            <input class="form-control" v-model="$v.subCategory.name.$model" type="text" />
                            <span v-if="$v.subCategory.name.$error" class="error">
                                <span v-if="!$v.subCategory.name.required"> Name is required</span>
                                <span v-if="!$v.subCategory.name.maxLength">Exceed max length</span>
                            </span>
                        </div>

                        <div class="form-group has-label col-sm-12 form-group">
                            <label class="text  font-weight-bolder">{{ $t('SubCategoryDropdown.Description') }} :</label>
                            <div v-bind:class="{ 'has-danger': $v.subCategory.description.$error }">
                                <textarea rows="3" class="form-control" v-model="$v.subCategory.description.$model" />
                                <span v-if="$v.subCategory.description.$error" class="error text-danger">

                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="subCategory.isActive">
                                <label for="inlineCheckbox1"> {{ $t('addBrand.Active') }} </label>
                            </div>
                        </div>


                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveSubCategory"
                    v-bind:disabled="$v.subCategory.$invalid" v-if="type != 'Edit' && isValid('CanAddSubCategory')">{{
                            $t('AddSubCategory.btnSave')
                    }}</button>
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveSubCategory"
                    v-bind:disabled="$v.subCategory.$invalid" v-if="type == 'Edit' && isValid('CanEditSubCategory')">{{
                            $t('AddSubCategory.btnUpdate')
                    }}</button>
                <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">{{
                        $t('AddSubCategory.btnClear')
                }}</button>
            </div>
            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
    
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default ({
    mixins: [clickMixin],
    name: 'AddSubCategory',
    props: ['show', 'subCategory', 'type', 'categoryid'],
    components: {
        Loading
    },
    data: function () {

        return {
            loading: false,
            arabic: '',
            english: '',
            rendered: 0,
        }
    },
    validations: {
        subCategory:
        {
            name: {
                maxLength: maxLength(250)
            },
            nameArabic: {
                required: requiredIf((x) => {
                    if (x.name == '' || x.name == null)
                        return true;
                    return false;
                }),
                maxLength: maxLength(250)
            },
            categoryId: {
                required
            },
            description: {
                maxLength: maxLength(500)
            },
        }
    },
    methods: {
        close: function () {
            this.$emit('close');
        },

        SaveSubCategory: function () {
            var root = this;
            this.loading = true;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveSubCategoryInformation', this.subCategory, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });

                            root.close();
                        }
                        else {
                            root.$swal({
                               title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update!' : 'تم التحديث!',
                                icon: 'success',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update Successfully!' : 'تم التحديث بنجاح',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            icon: 'error',
                            text: "Your Sub Category Already Exist!",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false);
        },
        AutoIncrementCode: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .get('/Product/SubCategoryAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.subCategory.code = response.data;
                        root.rendered++
                    }
                });
        }
    },


    mounted: function () {

        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
      
        if (this.categoryid != undefined) {
            this.subCategory.categoryId = this.categoryid;
        }
    }
})

</script>